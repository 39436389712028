import { IntermediaryType, SupportedLanguage } from "@aviv-ipp/bff-types"

export const LocalizedIntermediaryTypes: Record<
  SupportedLanguage,
  Record<IntermediaryType, { singular: string; plural: string }>
> = {
  en: {
    AGENCY: { singular: "agency", plural: "agencies" },
    NOTARY: { singular: "notary", plural: "notaries" },
    REAL_ESTATE_DEVELOPER: { singular: "property-developer", plural: "property-developers" },
  },
  fr: {
    AGENCY: { singular: "agence", plural: "agences" },
    NOTARY: { singular: "notaire", plural: "notaires" },
    REAL_ESTATE_DEVELOPER: { singular: "promoteur-immobilier", plural: "promoteur-immobilier" },
  },
  nl: {
    AGENCY: { singular: "agentschap", plural: "agentschappen" },
    NOTARY: { singular: "notaris", plural: "notarissen" },
    REAL_ESTATE_DEVELOPER: { singular: "vastgoedontwikkelaar", plural: "vastgoedontwikkelaars" },
  },
  de: {
    AGENCY: { singular: "agentur", plural: "agenturen" },
    NOTARY: { singular: "notar", plural: "notare" },
    REAL_ESTATE_DEVELOPER: { singular: "immobilienentwickler", plural: "immobilienentwickler" },
  },
}

type LocalizedIntermediaryTypeInput = {
  targetLanguage: SupportedLanguage
  sourceLanguage?: SupportedLanguage
  intermediaryType?: IntermediaryType
  plural?: boolean
  fromUrl?: boolean
}

export function getLocalizedIntermediaryType({
  targetLanguage,
  sourceLanguage,
  intermediaryType,
  plural = false,
  fromUrl = false,
}: LocalizedIntermediaryTypeInput): string {
  let intermediaryTypeKey = intermediaryType

  // Get the intermediary type key from the intermediary type value
  if (fromUrl) {
    intermediaryTypeKey = (
      Object.entries(LocalizedIntermediaryTypes[sourceLanguage as SupportedLanguage]).find(
        ([_, value]) => {
          return plural ? value.plural === intermediaryType : value.singular === intermediaryType
        }
      ) ?? []
    ).at(0) as IntermediaryType
  }

  if (!intermediaryTypeKey) {
    return ""
  }

  const localizedType = LocalizedIntermediaryTypes[targetLanguage][intermediaryTypeKey]
  return plural ? localizedType.plural : localizedType.singular
}
