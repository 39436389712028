import { IntermediaryType, SupportedLanguage } from "@aviv-ipp/bff-types"

import { getLocalizedIntermediaryType } from "./localizedIntermediaryTypes.ts"

export const SUPPORTED_LANGUAGES: SupportedLanguage[] = ["en", "fr", "nl"]

export const languageRegex = new RegExp(`^(${SUPPORTED_LANGUAGES.join("|")})$`)

export const extractLanguageFromUrl = (url: string) => {
  const segments = url.replace(/.*_ssr/, "").split("/")
  // TODO LANGUAGE: replace the language in the first segment (+ on worker)
  const match1 = languageRegex.exec(segments[1])
  const match2 = languageRegex.exec(segments[2])
  return match1?.[0] ?? match2?.[0] ?? undefined
}

export const createLocalesUrls = (forwardedHost: string, forwardedPath: string) => {
  const hasIntermediaryType = !forwardedPath.includes("_ssr")

  const usedLanguages = SUPPORTED_LANGUAGES

  if (hasIntermediaryType) {
    return createForKnownType(usedLanguages, forwardedHost, forwardedPath)
  }
  return createForUnknownType(usedLanguages, forwardedHost, forwardedPath)
}

const createForUnknownType = (
  usedLanguages: SupportedLanguage[],
  forwardedHost: string,
  forwardedPath: string
) => {
  const fragments = forwardedPath.split("_ssr")
  return usedLanguages.reduce((options, language) => {
    const url = `${forwardedHost}${fragments.at(0)}_ssr/${language}`
    return {
      ...options,
      [language]: url,
    }
  }, {} as unknown as Record<SupportedLanguage, string>) //TODO: needs to be refactored
}

const createForKnownType = (
  usedLanguages: SupportedLanguage[],
  forwardedHost: string,
  forwardedPath: string
) => {
  // forwardedPath starts with /locale
  const [, currentLanguage, localizedIntermediaryType, ...rest] = forwardedPath.split("/")
  if (!currentLanguage) {
    return {} as unknown as Record<SupportedLanguage, string>
  }

  return usedLanguages.reduce((options, language) => {
    const url = `${forwardedHost}/${language}/${getLocalizedIntermediaryType({
      targetLanguage: language,
      intermediaryType: localizedIntermediaryType as IntermediaryType,
      fromUrl: true,
      sourceLanguage: currentLanguage as SupportedLanguage,
    })}/${rest.join("/")}`
    return {
      ...options,
      [language]: url,
    }
  }, {} as unknown as Record<SupportedLanguage, string>) //TODO: needs to be refactored
}
